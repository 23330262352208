import {
  ref,
  watch,
  onUnmounted,
  getCurrentInstance,
} from "@vue/composition-api";
import store from "@/store";
import toast from "@/utils/toast";
import { parseDateToString } from "@/auth/utils";
import zaloStoreModule from "../zaloStoreModule";
import { useRouter } from "@/@core/utils/utils";

export default function useZaloCallback() {
  const APARTMENT_STORE_MODULE_NAME = "zalo";

  // Register module
  if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
    store.registerModule(APARTMENT_STORE_MODULE_NAME, zaloStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
      store.unregisterModule(APARTMENT_STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();
  const isLoading = ref(true);
  const vm = getCurrentInstance().proxy;
  const { route } = useRouter();
  const query = route.value.query;
  console.log(query);

  const linkOA = () => {
    store
      .dispatch("zalo/linkOA", query)
      .then((response) => {
        toastification.showToastSuccess('Liên kết thành công Zalo OA')
        vm.$router.push({ name: "zalo" });

      })
      .catch((error) => {
        toastification.showToastError(error);
        isLoading.value = false;
        vm.$router.push({ name: "zalo" });
      });
  };

  return {
    isLoading,
    linkOA,
  };
}
